import classes from "./MainContainer.module.css";
import React, { useContext } from "react";
import { PROTECTED_ROUTES, PROTECTED_ROUTES_ADMIN } from "../../routes/Routes";
import { Route, Switch } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { get as lodashGet } from "lodash";
import CompanyLogo from "../../assets/logo.png";
import { MenuItem, Menu } from "@szhsin/react-menu";
import profile from "../../assets/profile.jpg";

const MainContainer = ({ title }) => {
  const { state } = useContext(AuthContext);
  const logo = lodashGet(state, "user.properties.logo");
  const userRole = lodashGet(state, "user.userRole");

  const processedFilterRoutes = ["admin", "super-admin"].includes(userRole)
    ? PROTECTED_ROUTES_ADMIN
    : userRole === "user"
    ? PROTECTED_ROUTES
    : [];

  let processedRoutes = processedFilterRoutes
    .map((route) => {
      return route.childRoutes ? (
        route.childRoutes.map((childRoute) => (
          <Route
            exact
            path={`${route.url}${childRoute.url}`}
            component={childRoute.component}
          />
        ))
      ) : (
        <Route exact path={route.url} component={route.component} />
      );
    })
    .flat();

  const getProfileDetails = () => {
    return (
      <div className={classes.ProfilePreview}>
        {logo ? (
          <div className={classes.CompanyLogoWrapper}>
            <img src={logo} alt="logo" className={classes.CompanyLogo}/>
          </div>
        ) : (
          <div className={classes.CompanyLogoWrapper}>
            <img src={CompanyLogo} className={classes.CompanyLogo} alt="logo"/>
          </div>
        )}
        <div className={classes.ProfileImageWrapper}>
          <img
            src={state?.user?.profileImage || profile}
            alt="dp"
            className={classes.ProfileImage}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={classes.NavBar}>
        <div className={classes.NavBarStart}>
          <div className={classes.Title}>{title}</div>
        </div>
        <Menu
          menuButton={getProfileDetails()}
          align="end"
          arrow={true}
          menuClassName={classes.ProfileMenu}
        >
          <MenuItem className={classes.RoleName}>
            <div className={classes.UserName}>Hey, {state?.user?.name}</div>
            <div className={classes.UserRole}>
              User Role: {state?.user?.userRole}
            </div>
          </MenuItem>
        </Menu>
      </div>
      <div className={classes.DashBoard}>
        <Switch>{processedRoutes}</Switch>
      </div>
    </div>
  );
};

export default MainContainer;
