import React from 'react';
import Tooltip from 'react-tooltip-lite';
import classes from './Tooltip.module.css';

const CustomTooltip = props => {
  return (
    <div className={classes.Tooltip}>
      <Tooltip id={props.id} content={props.content} {...props}>
        {props.children}
      </Tooltip>
    </div>
  );
};

export default CustomTooltip;
