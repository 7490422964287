import React, { useEffect, useState } from "react";
import { useLoader } from "../../../hooks/use-loader.hook";
import Typography from "../../../components/Typography/Typography";
import Table from "../../../components/Table/Table";
import { RawLogService } from "../../../services/rawLogService";
import { toast } from "react-toastify";
import moment from "moment";
import DownloadButton from "../../../components/Buttons/DownloadButton";
import { DownloadAsExcel } from "../../../utils/downloadAsExcel";
import DatePicker from "../../../components/Inputs/DatePicker/DatePicker";
import { UilSync } from "@iconscout/react-unicons";
import classes from "./index.module.css";

const LIMIT = 20;
const AUTO_REFRESH_SEC = 10;

const RawLog = () => {
  const [startLoader, stopLoader] = useLoader();
  const [logs, setLogs] = useState("");
  const [page, setPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [date, setDate] = useState(moment().toDate());
  const [counterInterval, setCounterInterval] = useState(null);
  const [isAutoRefresh, setIsAutorefresh] = useState(false);
  const [seconds, setSeconds] = useState(AUTO_REFRESH_SEC);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [skip]);

  useEffect(() => {
    if (isAutoRefresh) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          fetchData();
          setSeconds(AUTO_REFRESH_SEC);
        }
      }, 1000);
      setCounterInterval(interval);
      return () => clearInterval(interval);
    }
  }, [isAutoRefresh, seconds]);

  const fetchData = () => {
    RawLogService.Get(
      {
        limit: LIMIT,
        skip,
        startTime: moment(date).startOf("day").unix(),
        endTime: moment(date).endOf("day").unix(),
      },
      () => toggleLoader(true, "getRawLogs"),
      handleSuccess,
      handleError,
      () => toggleLoader(false, "getRawLogs")
    );
  };

  const handleSuccess = ({ data }) => {
    if (data.data?.logs) {
      const processed = data.data.logs.map((item) => ({
        ...item,
        sTimestamp: moment(item.timestamp).toLocaleString(),
      }));
      setLogs(processed);
    }
    if (data.data?.totalCount >= 0) {
      setTotalCount(data.data.totalCount);
    }
  };

  const toggleLoader = (isStart, name) => {
    if (isAutoRefresh) {
      setLoading(isStart);
    } else {
      isStart ? startLoader(name) : stopLoader(name);
    }
  };

  const handleDownload = () => {
    RawLogService.Get(
      {
        limit: "all",
        skip: 0,
        startTime: moment(date).startOf("day").unix(),
        endTime: moment(date).endOf("day").unix(),
      },
      () => startLoader("getRawLogsForDownload"),
      handleDownloadSuccess,
      handleError,
      () => stopLoader("getRawLogsForDownload")
    );
  };

  const handleDownloadSuccess = ({ data }) => {
    if (data.data?.logs) {
      const headers = ["TOPIC", "LOG", "TIMESTAMP"];
      const dataForDownload = data.data.logs.map((item) => ({
        a: item.topic,
        b: item.message,
        f: moment(item.timestamp).toLocaleString(),
      }));
      DownloadAsExcel(headers, dataForDownload, "Raw Logs Export");
    }
  };

  const handleError = (err) => {
    if (err && err.response) toast.error(err.response.data.message);
  };

  const handlePageChange = (pageno) => {
    setSkip(LIMIT * pageno);
    setPage(pageno);
    setIsAutorefresh(false);
  };

  const toggleAutoRefresh = () => {
    clearInterval(counterInterval);
    setDate(moment().toDate());
    setIsAutorefresh(!isAutoRefresh);
    setSeconds(AUTO_REFRESH_SEC);
    setSkip(0);
    setPage(0);
  };

  return (
    <div className="rounded-content-area">
      <div className="content-header">
        <section className="action-container">
          <Typography content={`Raw Logs`} />
          <div className="action-container">
            <UilSync
              onClick={toggleAutoRefresh}
              size={"1vw"}
              className={`${classes.AutoRefreshIcon} ${
                loading ? classes.AutoRefreshIconLoading : ""
              }`}
            />
            {isAutoRefresh && (
              <label className="label">Refresing in {seconds} seconds</label>
            )}
          </div>
        </section>
        <div className="action-container">
          <div className="action-container">
            <DatePicker
              date={date}
              setDate={setDate}
              showArrow
              disabled={isAutoRefresh}
            />
            <button
              className="btn-primary"
              onClick={fetchData}
              disabled={isAutoRefresh}
            >
              Submit
            </button>
          </div>
          <div>
            <DownloadButton size={"medium"} onClick={handleDownload} />
          </div>
        </div>
      </div>
      <Table
        data={logs}
        head={["Topic", "Log", "Timestamp"]}
        keys={["topic", "message", "sTimestamp"]}
        pagination={true}
        page={page}
        limit={LIMIT}
        handlePageChange={handlePageChange}
        totalCount={totalCount}
      />
    </div>
  );
};

export default RawLog;
