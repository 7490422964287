import { HttpClient } from "../utils/httpClient";

const PATH = {
  get: "/user/raw-log",
  adminGet: "/admin/raw-log",
};

const Get = (params = {}, start, callback, error, next) => {
  start();
  return HttpClient.get(PATH.get, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const AminGet = (params = {}, start, callback, error, next) => {
  start();
  return HttpClient.get(PATH.adminGet, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

export const RawLogService = {
  Get,
};

export const AdminRawLogService = {
  Get: AminGet,
};
