import React from "react";
import TabsComponent from "../../../components/Tabs/Tabs";
import AllUsers from "./AllUsers";

const User = () => {
  const tabs = [
    {
      name: "Users",
      component: <AllUsers />,
    },
  ];

  return (
    <div className="react-tabs-background">
      <TabsComponent tabs={tabs} />
    </div>
  );
};

export default User;
