import React from "react";
import Typography from "../../../../components/Typography/Typography";
import { Form, Formik } from "formik";
import { Input } from "../../../../components/Inputs";
import { useLoader } from "../../../../hooks/use-loader.hook";
import { toast } from "react-toastify";
import {
  CreateUserValidation,
  UpdateUserValidation,
} from "../../../../validations/Admin/User";
import { AdminUserService } from "../../../../services/userService";
import Dropdown from "../../../../components/Inputs/Dropdown";
import classes from "../index.module.css";

const USER_ROLE_OPTIONS = [
  {
    label: "User",
    value: "user",
  },
  {
    label: "Admin",
    value: "admin",
  },
];

const getInitialValues = (editData) => {
  const values = {
    name: "",
    email: "",
    phone: "",
    password: null,
    userRole: "",
  };
  if (editData.id) {
    values.name = editData.name;
    values.email = editData.email;
    values.phone = editData.phone;
    values.userRole = editData.userRole;
  }
  return values;
};

const CreateEditUser = ({ editData = {}, setOpen, setRefresh = () => {} }) => {
  const [startLoader, stopLoader] = useLoader();
  const initialValues = getInitialValues(editData);

  const handleSubmit = (values) => {
    if (editData.id) {
      AdminUserService.Update(
        editData.id,
        values,
        () => startLoader("update"),
        handleSuccess,
        handleError,
        () => stopLoader("update")
      );
    } else {
      AdminUserService.Create(
        values,
        () => startLoader("create"),
        handleSuccess,
        handleError,
        () => stopLoader("create")
      );
    }
  };

  const handleSuccess = () => {
    setOpen(false);
    setRefresh(Date.now());
    toast.success(`User ${editData.id ? "updated" : "created"}!`);
  };

  const handleError = (err) => {
    if (err && err.response) toast.error(err.response.data.message);
  };

  return (
    <div className={`${classes.UserFromParent} overflow-initial`}>
      <Typography content={`${editData.id ? "Edit" : "Create"} User`} />
      <Formik
        onSubmit={handleSubmit}
        validationSchema={
          editData.id ? UpdateUserValidation : CreateUserValidation
        }
        initialValues={initialValues}
      >
        {({ errors, touched, values, isValidating, ...props }) => (
          <Form>
            <div className="input-container">
              <div className="field-container-full-width">
                <label htmlFor="name">Name</label>
                <Input name="name" />
              </div>
            </div>
            <div className="input-container">
              <div className="field-container-full-width">
                <label htmlFor="email">Email</label>
                <Input name="email" />
              </div>
            </div>
            <div className="input-container">
              <div className="field-container-full-width">
                <label htmlFor="phone">Phone</label>
                <Input name="phone" />
              </div>
            </div>
            <div className="input-container">
              <div className="field-container-full-width">
                <label htmlFor="password">User Role</label>
                <Dropdown
                  options={USER_ROLE_OPTIONS}
                  name="userRole"
                  defaultValue={{ value: initialValues.userRole }}
                />
              </div>
            </div>
            <div className="input-container">
              <div className="field-container-full-width">
                <label htmlFor="password">Password</label>
                <Input name="password" type="password" />
              </div>
            </div>
            <div className="buttons-container">
              <div>
                <button type="submit" className="btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateEditUser;
