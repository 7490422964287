import React from "react";
import TabsComponent from "../../../components/Tabs/Tabs";
import DataLog from "./DataLog";
import RawLog from "./RawLog";

const Company = () => {
  const tabs = [
    {
      name: "Data Logs",
      component: <DataLog />,
    },
    {
      name: "Raw Logs",
      component: <RawLog />,
    },
  ];

  return (
    <div className="react-tabs-background">
      <TabsComponent tabs={tabs} />
    </div>
  );
};

export default Company;
