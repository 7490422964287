import React, { useEffect, useState } from "react";
import { useLoader } from "../../../hooks/use-loader.hook";
import Typography from "../../../components/Typography/Typography";
import Table from "../../../components/Table/Table";
import { DataLogService } from "../../../services/dataLogService";
import { toast } from "react-toastify";
import { InputWithOutState } from "../../../components/Inputs";
import moment from "moment";
import CustomTooltip from "../../../components/CustomToolTip/CustomTooltip";
import { UilInfoCircle } from "@iconscout/react-unicons";
import { UilSync } from "@iconscout/react-unicons";
import DownloadButton from "../../../components/Buttons/DownloadButton";
import { DownloadAsExcel } from "../../../utils/downloadAsExcel";
import DatePicker from "../../../components/Inputs/DatePicker/DatePicker";
import classes from "./index.module.css";

const LIMIT = 20;
const AUTO_REFRESH_SEC = 10;

const DataLog = () => {
  const [startLoader, stopLoader] = useLoader();
  const [logs, setLogs] = useState("");
  const [page, setPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [deviceId, setDeviceId] = useState("");
  const [date, setDate] = useState(moment().toDate());
  const [counterInterval, setCounterInterval] = useState(null);
  const [isAutoRefresh, setIsAutorefresh] = useState(false);
  const [seconds, setSeconds] = useState(AUTO_REFRESH_SEC);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [skip]);

  useEffect(() => {
    if (isAutoRefresh) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          fetchData();
          setSeconds(AUTO_REFRESH_SEC);
        }
      }, 1000);
      setCounterInterval(interval);
      return () => clearInterval(interval);
    }
  }, [isAutoRefresh, seconds]);

  const fetchData = () => {
    DataLogService.Get(
      {
        limit: LIMIT,
        skip,
        deviceId,
        startTime: moment(date).startOf("day").unix(),
        endTime: moment(date).endOf("day").unix(),
      },
      () => toggleLoader(true, "getDataLogs"),
      handleSuccess,
      handleError,
      () => toggleLoader(false, "getDataLogs")
    );
  };

  const handleSuccess = ({ data }) => {
    if (data.data?.logs) {
      const processed = data.data.logs.map((item) => ({
        ...item,
        sTemperature: `${item.temperature} °C`,
        sHumidity: `${item.humidity} %`,
        sTimestamp: moment(item.timestamp).toLocaleString(),
        sInfo: (
          <CustomTooltip content={item.data}>
            <div className="action-container">
              <div>
                <UilInfoCircle
                  size={"1.2vw"}
                  style={{ color: "var(--color-primary)" }}
                />
              </div>
            </div>
          </CustomTooltip>
        ),
      }));
      setLogs(processed);
    }
    if (data.data?.totalCount >= 0) {
      setTotalCount(data.data.totalCount);
    }
  };

  const toggleLoader = (isStart, name) => {
    if (isAutoRefresh) {
      setLoading(isStart);
    } else {
      isStart ? startLoader(name) : stopLoader(name);
    }
  };

  const handleDownload = () => {
    DataLogService.Get(
      {
        limit: "all",
        skip: 0,
        deviceId,
        startTime: moment(date).startOf("day").unix(),
        endTime: moment(date).endOf("day").unix(),
      },
      () => startLoader("getDataLogsForDownload"),
      handleDownloadSuccess,
      handleError,
      () => stopLoader("getDataLogsForDownload")
    );
  };

  const handleDownloadSuccess = ({ data }) => {
    if (data.data?.logs) {
      const headers = [
        "DEVICE ID",
        "PORT",
        "HARDWARE VERSION",
        "TEMPERATURE",
        "HUMIDITY",
        "TIMESTAMP",
        "INFO",
      ];
      const dataForDownload = data.data.logs.map((item) => ({
        a: item.deviceId,
        b: item.port,
        c: item.hardwareVersion,
        d: item.temperature,
        e: item.humidity,
        f: moment(item.timestamp).toLocaleString(),
        g: item.data,
      }));
      DownloadAsExcel(headers, dataForDownload, "Data Logs Export");
    }
  };

  const handleError = (err) => {
    if (err && err.response) toast.error(err.response.data.message);
  };

  const handlePageChange = (pageno) => {
    setSkip(LIMIT * pageno);
    setPage(pageno);
    setIsAutorefresh(false);
  };

  const toggleAutoRefresh = () => {
    clearInterval(counterInterval);
    setDate(moment().toDate());
    setIsAutorefresh(!isAutoRefresh);
    setSeconds(AUTO_REFRESH_SEC);
    setSkip(0);
    setPage(0);
  };

  return (
    <div className="rounded-content-area">
      <div className="content-header">
        <section className="action-container">
          <Typography content={`Data Logs`} />
          <div className="action-container">
            <UilSync
              onClick={toggleAutoRefresh}
              size={"1vw"}
              className={`${classes.AutoRefreshIcon} ${
                loading ? classes.AutoRefreshIconLoading : ""
              }`}
            />
            {isAutoRefresh && (
              <label className="label">Refresing in {seconds} seconds</label>
            )}
          </div>
        </section>
        <div className="action-container">
          <div className="action-container">
            <InputWithOutState
              placeHolder={"Enter Device ID"}
              value={deviceId}
              onChange={(e) => setDeviceId(e.target.value)}
              className={classes.CustomInputBox}
              disabled={isAutoRefresh}
            />

            <DatePicker
              date={date}
              setDate={setDate}
              showArrow
              disabled={isAutoRefresh}
            />

            <button
              className="btn-primary"
              onClick={fetchData}
              disabled={isAutoRefresh}
            >
              Submit
            </button>
          </div>
          <div>
            <DownloadButton size={"medium"} onClick={handleDownload} />
          </div>
        </div>
      </div>
      <Table
        data={logs}
        head={[
          "Device ID",
          "Port",
          "Hardware Version",
          "Temperature",
          "Humidity",
          "Timestamp",
          "Info",
        ]}
        keys={[
          "deviceId",
          "port",
          "hardwareVersion",
          "sTemperature",
          "sHumidity",
          "sTimestamp",
          "sInfo",
        ]}
        pagination={true}
        page={page}
        limit={LIMIT}
        handlePageChange={handlePageChange}
        totalCount={totalCount}
      />
    </div>
  );
};

export default DataLog;
