// icons
import CompanyIcon from "../assets/sidebar/company.svg";
import DataLogIcon from "../assets/sidebar/dataLog.svg";

// Admin
import Company from "../containers/Admin/User";
import DataLog from "../containers/Admin/DataLog";

// User
import UserDataLog from "../containers/User/DataLog";

export const PROTECTED_ROUTES = [
  {
    name: "Data Logs",
    id: "data-log",
    url: "/",
    component: UserDataLog,
    icon: DataLogIcon,
  },
];

export const PROTECTED_ROUTES_ADMIN = [
  {
    name: "Data Logs",
    id: "data-log",
    url: "/",
    component: DataLog,
    icon: DataLogIcon,
  },
  {
    name: "Users",
    id: "Users",
    url: "/users",
    component: Company,
    icon: CompanyIcon,
  },
];
