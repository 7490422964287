import * as XLSX from "xlsx";

export const DownloadAsExcel = (passedHeaders, data, fileName) => {
  let processedData = [...data],
    processedHeader = {};

  Object.keys(data[0]).forEach((header, i) => {
    processedHeader[header] = passedHeaders[i];
  });
  processedData.splice(0, 0, processedHeader);
  const workBook = XLSX.utils.book_new();
  const workSheet = XLSX.utils.json_to_sheet(processedData, {
    skipHeader: true,
  });
  XLSX.utils.book_append_sheet(workBook, workSheet, ["sheet1"]);
  XLSX.writeFile(workBook, `${fileName}.xlsx`);
};

export const DownloadAsExcelWithBuffer = (base64String, fileName) => {
  const buffer = Buffer.from(base64String, "base64");
  const workbook = XLSX.read(buffer, { type: "buffer", cellStyles: true });
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};
